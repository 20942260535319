
import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { LocationStats, PlaceStats, TextValuePair } from '@/interfaces';
import { StatsPayload } from '@/store/interfaces';
import DatePickerModal from '@/components/modals/DatePicker.vue';

const appStore = namespace('app');
const ordersStore = namespace('orders');

@Component({ components: { DatePickerModal } })
export default class StatsPage extends Vue {
  stats = [];
  range = 1;
  dates: string[] = [];
  statsType = 1;
  showEmpty = false;
  showInternal = false;

  ordersSortKey = 'Orders';
  locationsSortKey = 'Location';

  sortKey = '';
  sortDirection = '';
  sortOrder = 0;

  @appStore.Getter
  readonly isRebel!: string;

  get ranges(): TextValuePair[] {
    return [
      { text: this.$t('stats.today') as string, value: 1 },
      { text: this.$t('stats.lastWeek') as string, value: 2 },
      { text: this.$t('stats.lastMonth') as string, value: 3 },
      { text: this.$t('stats.custom') as string, value: 4 },
    ];
  }

  get showDatePicker(): boolean {
    return this.range === 4 && !this.dates.length;
  }

  get statsTypes(): { value: number; text: string }[] {
    const options = [
      { value: 1, text: this.$t('stats.locations') as string },
      { value: 3, text: this.$t('stats.menu') as string },
    ];
    if (this.isRebel) {
      options.push({ value: 2, text: this.$t('stats.restaurants') as string });
    }
    return options.sort((a, b) => (a.value > b.value ? 1 : -1));
  }

  get header(): string {
    const headers = [
      this.$t('stats.location'),
      this.$t('stats.restaurant'),
      this.$t('stats.menuItem'),
    ];
    return headers[this.statsType - 1] as string;
  }

  get sortKeys(): string {
    const headers = ['Location', 'Restaurant', 'Menu'];
    return headers[this.statsType - 1] as string;
  }

  @Watch('range')
  onRangeChange(val: number): void {
    if (!this.showDatePicker && val) {
      this.dates = [];
      this.getStats();
    }
  }

  @Watch('showEmpty')
  onShowEmptyChange(): void {
    this.getStats();
  }

  @Watch('showInternal')
  onShowInternalChange(): void {
    this.getStats();
  }

  @Watch('dates')
  onDatesChange(val: string[]): void {
    val.length && this.getStats();
  }

  @Watch('statsType')
  onStatsTypeChange(): void {
    this.getStats();
  }

  mounted(): void {
    this.getStats();
  }

  @ordersStore.Action
  getLocationsStats!: (data: StatsPayload) => Promise<LocationStats[]>;

  @ordersStore.Action
  getRestaurantsStats!: (data: StatsPayload) => Promise<PlaceStats[]>;

  @ordersStore.Action
  getMenuStats!: (data: StatsPayload) => Promise<LocationStats[]>;

  sortItems(sortKey): void {
    if (sortKey === this.sortKey) {
      this.sortOrder = -this.sortOrder;
    } else {
      this.sortKey = sortKey;
      this.sortOrder = 1;
    }

    this.sortDirection = this.sortOrder === 1 ? 'ASC' : 'DESC';
    this.getStats();
  }

  async getStats(): Promise<void> {
    const { range, dates, showEmpty, sortDirection, sortKey, locationsSortKey, showInternal } = this;
    console.log(showInternal)
    const reportTypes = ['getLocationsStats', 'getRestaurantsStats', 'getMenuStats'];
    const sortKeyValue = sortKey === this.$t('stats.location') ? locationsSortKey : sortKey;
    this.stats = await this[reportTypes[this.statsType - 1]]({
      range,
      dates,
      showEmpty,
      sortDirection,
      sortKey: sortKeyValue,
      showInternal,
    });
  }

  datePickerClosed(): void {
    if (!this.dates.length) {
      this.range = 0;
    }
  }
  rangeSelectClicked(e: number): void {
    if (e == 4 && 4 == this.range) {
      this.dates = [];
    }
  }
}


  import { Component, Vue } from "vue-property-decorator";

  @Component
  export default class DatePickerModal extends Vue {
    visible = true;
    dates: string[] = [];

    save(): void {
      this.$emit('submit', this.dates);
      this.closeModal();
    }

    closeModal(): void {
      this.$emit('close');
    }
  }
